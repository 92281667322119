export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
            instrumentIds
        }
    }
`;

export const listApplications = /* GraphQL */ `
    query ListApplications(
        $limit: Int
        $nextToken: String
        $filter: ModelApplicationFilterInput
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                festival {
                    name
                }
                instrument {
                    id
                    name
                    order
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    grade
                    school {
                        id
                        name {
                            legal
                        }
                    }
                }
                ranking {
                    score
                    local
                }
                recommendation
                applicationFestivalId
                applicationInstrumentId
            }
            nextToken
        }
    }
`;


export const getApplicationDetails = /* GraphQL */ `
    query GetApplication(
        $id: ID!
    ) {
        getApplication(id: $id) {
            id
            form {
                id
                name
                type
            }
            teacher {
                id
                username
                name {
                    first
                    last
                }
            }
            grading {
                tone
                rhythmicSense
                leadership
                maturity
                articulation
                intonation
                citizenship
                diction
                flexibility
                generalMusicianship
                attitude
                technique
                sightReading
                responsibility
            }
            comments
        }
    }
`;
