<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`"/>
      <b-breadcrumb-item active text="Application Recommendations"/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{state}">
      <table-layout ref="table-layout"
                    :export-exclude-fields="[
                        'id',
                        'student.id',
                        'student.school.id',
                        'instrument.id',
                        'applicationFestivalId',
                        'applicationInstrumentId',
                        'hasDetails'
                    ]"
                    :fields="table.fields"
                    :filters="table.filters" :filters-options="{ visible: true, collapsed: false, cols: 3 }"
                    :items="table.items"
                    :loading="table.loading"
                    @mounted="table = $event"
                    @updated="table = $event">

        <template #overlay>
          <overlay-loading :items="[
            { state: table.loading, desc: 'Loading Applications', loaded: table.loaded},
            { state: state.loading, desc: 'Rendering Template'},
          ]"/>
        </template>

        <template #filters>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Instrument" label-for="instrument-input">
                <v-select id="instrument-input"
                          v-model="table.filters.instrument.value"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :loading="options.instruments.loading"
                          :options="options.instruments.items"
                          :reduce="instrument => instrument.id"
                          :select-on-tab="true"
                          class="w-100 d-print-none"
                          label="name"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Recommendation" label-for="recommendation-input">
                <v-select id="recommendation-input"
                          v-model="table.filters.recommendation.value"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :loading="options.recommendations.loading"
                          :options="options.recommendations.items"
                          :select-on-tab="true"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Grade" label-for="grade-input">
                <v-select id="grade-input"
                          v-model.number="table.filters.grade.value"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :loading="options.grades.loading"
                          :options="options.grades.items"
                          :select-on-tab="true"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <template #row-details="{item}">
          <b-overlay :show="!item.hasDetails" opacity="1" @shown="getApplicationDetails(item)">
            <b-card class="mb-0 d-print-none" header-class="pb-0" no-body>
              <template v-if="item.hasDetails">
                <b-tabs card nav-wrapper-class="pt-1 pb-0">
                  <b-tab lazy title="Grading">
                    <application-grading :comments.sync="item.comments"
                                         :editable="false"
                                         :form="item.form"
                                         :grading.sync="item.grading"
                                         :recommendation.sync="item.recommendation"
                                         :show-heading="false" :student="item.student"/>
                  </b-tab>
                  <b-tab lazy title="Teacher">
                    <b-row>
                      <template v-if="item.teacher">
                        <b-col cols="12">
                          <b-form-group :label-for="`teacher-name-${item.teacher.id}`" label="Name">
                            <b-input :id="`teacher-name-${item.teacher.id}`"
                                     :value="`${item.teacher.name.first} ${item.teacher.name.last}`" disabled></b-input>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group :label-for="`teacher-email-${item.teacher.id}`" label="Email">
                            <b-input :id="`teacher-email-${item.teacher.id}`" :value="item.teacher.email"
                                     disabled></b-input>
                          </b-form-group>
                        </b-col>
                        <b-col cols="6">
                          <b-form-group :label-for="`teacher-phone-${item.teacher.id}`" label="Phone Number">
                            <b-input :id="`teacher-phone-${item.teacher.id}`" :value="item.teacher.phone"
                                     disabled></b-input>
                          </b-form-group>
                        </b-col>
                      </template>
                      <template v-else>
                        <b-col cols="12">
                          <b-alert show variant="danger">No Teacher Assigned</b-alert>
                        </b-col>
                      </template>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </template>
              <template v-else>
                <b-alert show variant="light">Loading</b-alert>
              </template>
            </b-card>
          </b-overlay>
        </template>

        <template #cell(student)="{data}">
          <b-media class="d-print-none" no-body vertical-align="center">
            <b-media-aside>
              <b-avatar badge-variant="primary"
                        button size="2.5em"
                        variant="primary"
                        @click="data.toggleDetails">
                <font-awesome-icon icon="fas fa-graduation-cap"></font-awesome-icon>
                <template #badge>
                  <b-icon :icon="data.item._showDetails === true ? 'chevron-up' : 'chevron-down'"/>
                </template>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <h6 class="mb-0">{{ data.item.student.name.first }} {{ data.item.student.name.last }}</h6>
              <template>
                <small v-if="hasValue(data, 'item.student.school.name')"
                       class="student-school d-print-none">{{ data.item.student.school.name.legal }}</small>
                <small v-else class="student-school text-danger d-print-none">No School</small>
              </template>

            </b-media-body>
          </b-media>
          <span class="d-none d-print-block">{{ data.item.student.name.first }} {{ data.item.student.name.last }}</span>
        </template>

        <template #cell(festival.name)="{data}">
          <div v-if="hasValue(data, 'item.festival')">
            {{ data.item.festival.name }}
          </div>
          <div v-else class="text-danger">
            No Festival
          </div>
        </template>

        <template #cell(instrument.name)="{data}">
          <div v-if="hasValue(data, 'item.instrument')">
            {{ data.item.instrument.name }}
          </div>
          <div v-else class="text-danger">
            No Instrument
          </div>
        </template>

      </table-layout>

    </template>

  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser, listApplications, getApplicationDetails} from './queries/applications-recommendation'
import vSelect from 'vue-select';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import {adminGetUser} from '@/scripts/aws';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import {recommendations, grades} from '@/data/application'

export default {
  name: 'ApplicationRecommendation',
  components: {
    ApplicationGrading,
    OverlayLoading,
    TableLayout,
    PageLayout,
    vSelect
  },
  mixins: [lodashMixin, settingsMixin],
  data() {
    return {
      user: this.$store.state.user,
      table: {
        fields: [
          {
            key: 'student',
            subKeys: ['name.first', 'name.last', 'school.name.legal'],
            label: 'Student',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.grade',
            label: 'Grade',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'festival.name',
            label: 'Festival',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'ranking.local',
            label: 'Local Rank',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'ranking.score',
            label: 'Score',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'recommendation',
            label: 'Recommendation',
            sortable: true,
            filterable: false,
            visible: true,
            tdClass: 'align-middle'
          },
        ],
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              {
                text: 'Fuzzy',
                value: ''
              },
              {
                text: 'Exact',
                value: '='
              },
              {
                text: 'Includes',
                value: '\''
              },
              {
                text: 'Starts With',
                value: '^'
              },
            ]
          },
        },
        filters: {
          instrument: {
            key: 'applicationInstrumentId',
            value: null
          },
          recommendation: {
            key: 'recommendation',
            value: null
          },
          grade: {
            key: 'student.grade',
            value: null
          },
        },
        items: [],
        loading: false
      },
      options: {
        instruments: {
          items: [],
          loading: true
        },
        recommendations: {
          items: recommendations,
          loading: false
        },
        grades: {
          items: grades,
          loading: false
        },
        teachers: {
          items: [],
          loading: true
        }
      },
    }
  },
  async mounted() {
    await this.getCurrentUser()
    await this.listApplications()
    this.$refs.layout.state.loading = false
  },
  methods: {
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, {id: cognitoUser.attributes['custom:user_id']}));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },

    async listApplications(nextToken, pagedApplications) {
      const applications = pagedApplications || []
      const input = {
        limit: 500,
        nextToken: nextToken,
        filter: {
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          }
        }
      }

      const response = await API.graphql(graphqlOperation(listApplications, input));
      applications.push(...response.data.listApplications.items)

      if (response.data.listApplications.nextToken) {
        await this.listApplications(response.data.listApplications.nextToken, applications)
      } else {
        this.table.items = applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0)
        this.table.loading = false

        const instrumentsMap = {};
        this.table.items.forEach(item => {
          item.hasDetails = false
          if (item?.instrument?.id) {
            instrumentsMap[item.instrument.id] = {
              id: item.instrument.id,
              name: item.instrument.name,
            };
          }
        })
        this.options.instruments.items = Object.values(instrumentsMap)
            .sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))
        if (!this.options.instruments.items.some(item => item.id === this.table.filters.instrument.value)) {
          this.table.filters.instrument.value = null
        }
        this.options.instruments.loading = false
      }
    },
    async getApplicationDetails(item) {
      if (item.hasDetails === false) {
        const response = await API.graphql(graphqlOperation(getApplicationDetails, {id: item.id}));
        const application = response.data.getApplication
        if (application) {
          //Get Cognito User - Contains additional information

          let teacher = null
          if (application?.teacher) {
            if (this.options.teachers.items.some(t => t.id === application?.teacher?.id)) {
              teacher = this.options.teachers.items.find(t => t.id === application?.teacher?.id)
            } else {
              const cognitoUser = await adminGetUser(application?.teacher?.username)
              if (cognitoUser) {
                teacher = {
                  id: application.teacher.id,
                  username: application.teacher.username,
                  name: application.teacher.name,
                  //schools: application.teacher.schools,
                  email: cognitoUser.UserAttributes.find(attr => attr.Name === 'email')?.Value,
                  phone: cognitoUser.UserAttributes.find(attr => attr.Name === 'phone_number')?.Value
                }
              }

              if (teacher) {
                this.options.teachers.items.push(teacher)
              }
            }
          }


          // Update the item with the new information
          const updatedItem = {...item};
          updatedItem.teacher = teacher
          updatedItem.form = application?.form
          updatedItem.grading = application?.grading
          updatedItem.comments = application?.comments
          updatedItem.hasDetails = true

          // Find the index of the item in the array
          const index = this.table.items.findIndex(i => i.id === item.id);

          // Replace the old item with the updated item using Vue.set to ensure reactivity
          if (index !== -1) {
            this.$set(this.table.items, index, updatedItem);
          }
        } else {
          item.hasDetails = true
        }
      }
    },
    async refresh() {
      this.table.loading = true
      this.options.instruments.loading = true
      await this.listApplications()
    },
    print() {
      this.$nextTick(() => {
        window.print()
      })
    }
  }
}
</script>

<style scoped>
@media print {
  table > tbody > tr > td {
    font-size: 12px !important;
  }

  .print-title {
    display: block !important;
  }

  .badge {
    border-width: 0;
    padding: 0;
    background: transparent;
    color: inherit;
    font-weight: 400;
  }
}
</style>
