var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"active":"","text":"Application Recommendations"}})]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('table-layout',{ref:"table-layout",attrs:{"export-exclude-fields":[
                      'id',
                      'student.id',
                      'student.school.id',
                      'instrument.id',
                      'applicationFestivalId',
                      'applicationInstrumentId',
                      'hasDetails'
                  ],"fields":_vm.table.fields,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false, cols: 3 },"items":_vm.table.items,"loading":_vm.table.loading},on:{"mounted":function($event){_vm.table = $event},"updated":function($event){_vm.table = $event}},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
          { state: _vm.table.loading, desc: 'Loading Applications', loaded: _vm.table.loaded},
          { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"instrument-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"instrument-input","clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"loading":_vm.options.instruments.loading,"options":_vm.options.instruments.items,"reduce":function (instrument) { return instrument.id; },"select-on-tab":true,"label":"name"},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Recommendation","label-for":"recommendation-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"recommendation-input","clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"loading":_vm.options.recommendations.loading,"options":_vm.options.recommendations.items,"select-on-tab":true},model:{value:(_vm.table.filters.recommendation.value),callback:function ($$v) {_vm.$set(_vm.table.filters.recommendation, "value", $$v)},expression:"table.filters.recommendation.value"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Grade","label-for":"grade-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"grade-input","clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"loading":_vm.options.grades.loading,"options":_vm.options.grades.items,"select-on-tab":true},model:{value:(_vm.table.filters.grade.value),callback:function ($$v) {_vm.$set(_vm.table.filters.grade, "value", _vm._n($$v))},expression:"table.filters.grade.value"}})],1)],1)],1)]},proxy:true},{key:"row-details",fn:function(ref){
        var item = ref.item;
return [_c('b-overlay',{attrs:{"show":!item.hasDetails,"opacity":"1"},on:{"shown":function($event){return _vm.getApplicationDetails(item)}}},[_c('b-card',{staticClass:"mb-0 d-print-none",attrs:{"header-class":"pb-0","no-body":""}},[(item.hasDetails)?[_c('b-tabs',{attrs:{"card":"","nav-wrapper-class":"pt-1 pb-0"}},[_c('b-tab',{attrs:{"lazy":"","title":"Grading"}},[_c('application-grading',{attrs:{"comments":item.comments,"editable":false,"form":item.form,"grading":item.grading,"recommendation":item.recommendation,"show-heading":false,"student":item.student},on:{"update:comments":function($event){return _vm.$set(item, "comments", $event)},"update:grading":function($event){return _vm.$set(item, "grading", $event)},"update:recommendation":function($event){return _vm.$set(item, "recommendation", $event)}}})],1),_c('b-tab',{attrs:{"lazy":"","title":"Teacher"}},[_c('b-row',[(item.teacher)?[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":("teacher-name-" + (item.teacher.id)),"label":"Name"}},[_c('b-input',{attrs:{"id":("teacher-name-" + (item.teacher.id)),"value":((item.teacher.name.first) + " " + (item.teacher.name.last)),"disabled":""}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":("teacher-email-" + (item.teacher.id)),"label":"Email"}},[_c('b-input',{attrs:{"id":("teacher-email-" + (item.teacher.id)),"value":item.teacher.email,"disabled":""}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":("teacher-phone-" + (item.teacher.id)),"label":"Phone Number"}},[_c('b-input',{attrs:{"id":("teacher-phone-" + (item.teacher.id)),"value":item.teacher.phone,"disabled":""}})],1)],1)]:[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v("No Teacher Assigned")])],1)]],2)],1)],1)]:[_c('b-alert',{attrs:{"show":"","variant":"light"}},[_vm._v("Loading")])]],2)],1)]}},{key:"cell(student)",fn:function(ref){
        var data = ref.data;
return [_c('b-media',{staticClass:"d-print-none",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"badge-variant":"primary","button":"","size":"2.5em","variant":"primary"},on:{"click":data.toggleDetails},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('b-icon',{attrs:{"icon":data.item._showDetails === true ? 'chevron-up' : 'chevron-down'}})]},proxy:true}],null,true)},[_c('font-awesome-icon',{attrs:{"icon":"fas fa-graduation-cap"}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(data.item.student.name.first)+" "+_vm._s(data.item.student.name.last))]),[(_vm.hasValue(data, 'item.student.school.name'))?_c('small',{staticClass:"student-school d-print-none"},[_vm._v(_vm._s(data.item.student.school.name.legal))]):_c('small',{staticClass:"student-school text-danger d-print-none"},[_vm._v("No School")])]],2)],1),_c('span',{staticClass:"d-none d-print-block"},[_vm._v(_vm._s(data.item.student.name.first)+" "+_vm._s(data.item.student.name.last))])]}},{key:"cell(festival.name)",fn:function(ref){
        var data = ref.data;
return [(_vm.hasValue(data, 'item.festival'))?_c('div',[_vm._v(" "+_vm._s(data.item.festival.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Festival ")])]}},{key:"cell(instrument.name)",fn:function(ref){
        var data = ref.data;
return [(_vm.hasValue(data, 'item.instrument'))?_c('div',[_vm._v(" "+_vm._s(data.item.instrument.name)+" ")]):_c('div',{staticClass:"text-danger"},[_vm._v(" No Instrument ")])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }